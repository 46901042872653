"use client"

import "@/styles/globals.css";
import "@/styles/prosemirror.css";
import { Libre_Baskerville, Montserrat, Oleo_Script, Playfair_Display, Lobster_Two, Indie_Flower, Marck_Script, Playpen_Sans } from "next/font/google";

import type { Viewport } from "next";
import type { ReactNode } from "react";
import Providers from "./providers";

import Script from 'next/script';

import { generateMetadata } from './metadata'

const libreBaskerville = Libre_Baskerville({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const montserrat = Montserrat({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const oleoScript = Oleo_Script({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const playfairDisplay = Playfair_Display({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const lobsterTwo = Lobster_Two({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const indieFlower = Indie_Flower({
  weight: ['400', '400'],
  subsets: ['latin'],
  display: 'swap',
});

const marckScript = Marck_Script({
  weight: ['400', '400'],
  subsets: ['latin'],
  display: 'swap',
});

const playpenSans = Playpen_Sans({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const viewport: Viewport = {
  themeColor: "#ffffff",
};

export default function RootLayout({ children }: { children: ReactNode }) {
  return (
    
    <html lang="en" suppressHydrationWarning>
      <head>
      <Script
      id='hotjar'
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:4941715,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        }}
    />
      <title>Quillow - A living second brain for your notes</title>
        <meta name="description" content="An intelligent notepad for unstructured thought" />
        <meta property="og:title" content="Quillow - A living second brain for your notes" />
        <meta property="og:description" content="An intelligent notepad for unstructured thought" />
        <meta property="og:image" content="/opengraph-image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Quillow - A living second brain for your notes" />
        <meta name="twitter:description" content="An intelligent notepad for unstructured thought" />
        <meta name="twitter:image" content="/opengraph-image.png" />
        <style jsx global>{`
          :root {
            --font-libre-baskerville: ${libreBaskerville.style.fontFamily};
            --font-montserrat: ${montserrat.style.fontFamily};
            --font-oleo-script: ${oleoScript.style.fontFamily};
            --font-playfair-display: ${playfairDisplay.style.fontFamily};
            --font-lobster-two: ${lobsterTwo.style.fontFamily};
            --font-indie-flower: ${indieFlower.style.fontFamily};
            --font-marck-script: ${marckScript.style.fontFamily};
            --font-playpen-sans: ${playpenSans.style.fontFamily};
          }
        `}</style>
      </head>
      <body>
        <Providers>{children}</Providers>
      </body>
    </html>
  );
}
